import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../../config/api";
import { Button, Card, message } from "antd";
import Loadingbox from "../../components/loadingBox";
import DataTable from "./components/dataTable";
import BannerNewForm from "./components/NewForm";
import "./styles.scss";
import { Container } from "react-bootstrap";
import PageHeader from "../route/pageHeader";
import { DELETE, POST } from "../../utils/apiCalls";

function Banner() {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [newForm, SetNewForm] = useState(false);
  const [Edit, setEdit] = useState(false);

  const [editData, setEditDAta] = useState<any>();
  const [data, setData] = useState<any>();
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>();

  useEffect(() => {
    loadData(page, take);
  }, []);

  const loadData = async (page: any, take: any) => {
    try {
      const url = API.LIST_BANNER;

      let obj = {
        page: page,
        take: take,
      };
      let response: any = await POST(url, obj);
      if (response) {
        setData(response?.data);
        setMeta(response?.meta);
        setIsloading(false);
      }
    } catch (error) {
      console.log("=error=", error);
    }
  };

  const deleteAuction = async (val: any) => {
    try {
      let url = API.DELETE_BANNER + `/${val}`;
      let response: any = await DELETE(url);

      if (response) {
        message.success("successfully deleted");
        loadData(page, take);
      }
    } catch (error) {}
  };

  const onPageChange = (page: any, take: any) => {
    setPage(page);
    setTake(take);
    loadData(page, take);
  };
  const openEdit = (val: any) => {
    setEdit(true);
    setEditDAta(val);
  };
  return (
    <div className="Banner_box">
      <PageHeader title={"Banner"} bredcume={"Banner"}>
        <div>
          <Button type="primary" onClick={() => SetNewForm(true)}>
            Create Banner +{" "}
          </Button>
        </div>
      </PageHeader>{" "}
      {isLoading ? (
        <Loadingbox />
      ) : (
        <>
          <Container>
            <Card style={{ width: "100%" }}>
              <DataTable
                meta={meta}
                data={data}
                onDelete={(val: any) => deleteAuction(val)}
                onPageChange={(p: any, t: any) => onPageChange(p, t)}
                onEdit={(val: any) => openEdit(val)}
              />
            </Card>
          </Container>
        </>
      )}
      {newForm ? (
        <BannerNewForm
          refresh={() => loadData(page, take)}
          visible={newForm}
          close={() => SetNewForm(false)}
        />
      ) : null}
      {Edit ? (
        <BannerNewForm
          data={editData}
          refresh={() => loadData(page, take)}
          visible={Edit}
          close={() => setEdit(false)}
        />
      ) : null}
    </div>
  );
}

export default Banner;
