import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../../config/api";
import { GET, POST } from "../../../utils/apiCalls";
import PageHeader from "../../route/pageHeader";
import "../styles.scss";
import PagesList from "./pageList";

const { Dragger } = Upload;

function AuctionNewForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [endDate, setEndDate] = useState<any>();
  const [Data, setData] = useState<any>();
  const [category, setCategory] = useState<any>();
  const [user, setUser] = useState<any>();
  const [currentUploadIndex, setCurrentUploadIndex] = useState<any>(null);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    GetCategories();
    if (id) {
      loadData(id);
    } else {
      getUsers();
    }
  }, []);

  const loadData = async (id: any) => {
    try {
      let url = API.GET_A_AUCTION + `/${id}`;

      let response: any = await GET(url, null);
      if (response?.status) {
        setData(response?.data);
        setPages(response?.data?.images);
        setEndDate(response?.data?.enddate);
        form.setFieldsValue({
          name: response?.data?.name,
          description: response?.data?.description,
          prize: response?.data?.price,
          location: response?.data?.location,
          licenceno: response?.data?.licenceno,
          end_date: response?.data?.enddate
            ? moment(response?.data?.enddate)
            : null,
          category: response?.data?.category?.id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUsers = async () => {
    let url = API.GET_USER_USER;
    let response: any = await GET(url, null);
    if (response) {
      setUser(response?.data);
    }
  };

  const GetCategories = async () => {
    let url = API.GET_CATEGORIES;
    let response: any = await GET(url, null);
    if (response) {
      setCategory(response?.data);
    }
  };

  const onFinish = async (val: any) => {
    setIsLoading(true);
    try {
      const location = pages?.map((item: any) => {
        return item;
      });
      let CreateUrl = API.CREATE_AUCTION;
      let UpdatUlrl = API.UPDATE_AUCTION;
      let obj = {
        name: val?.name || "",
        description: val?.description || "",
        price: Number(val?.prize) || null,
        location: val?.location || "",
        images: location,
        licenceno: val?.licenceno,
        enddate: endDate,
        category_id: Number(val?.category),
      };

      let UpdateObj = {
        name: val?.name || "",
        description: val?.description || "",
        price: Number(val?.prize) || null,
        location: val?.location || "",
        id: Data?.id,
        images: location,
        licenceno: val?.licenceno || "",
        enddate: endDate,
        category_id: Number(val?.category),
      };
      let response: any = await POST(
        Data?.id ? UpdatUlrl : CreateUrl,
        Data?.id ? UpdateObj : obj
      );

      if (response?.status) {
        setIsLoading(false);
        message.success("successfull");
        if (!id) {
          createNotification(response?.data);
        }
        navigate("/Auth/auction");
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const uploadeImage = async (val: any) => {
    let file = val;
    try {
      let data = new FormData();
      data.append("file", file);
      let config = {
        method: "post",
        url: API.BASE_URL + API.COMPRESS_IMAGE,
        data: data,
      };
      let response = await axios.request(config);
      if (response) {
        message.success("Uploaded Image Successfully");
        return response;
      }
    } catch (error) {
      message.error("Failed to upload image. Please try again");
      console.log(error);
    }
  };

  const handleDateChange = (date: any, dateString: any) => {
    setEndDate(date.toISOString());
  };
  const onchange = (val: any) => {
    var arro: any = [];
    for (let i = 0; i < val?.length; i++) {
      arro.push(val[i].file ? val[i].file : val[i]);
    }
    setPages(arro);
  };

  const createNotification = async (data: any) => {
    user?.map(async (item: any, index: any) => {
      let url = API.CREATE_NOTIFICATION;
      let body = {
        UserId: Number(item?.id),
        auction_ID: Number(data?.id),
        Heading: `Start Bidding: New Auction for ${data?.name} Just Launched!`,
        Body: `Exciting news! A new auction for ${data?.name} has just started.  Place your bids and don't miss out on this amazing opportunity. Happy bidding!`,
      };
      let response: any = await POST(url, body);
      if (response?.status) {
      }
    });
  };

  return (
    <div className="DashBoard_box">
      <PageHeader title={"Create Auctions"} bredcume={"Create Auction"} />
      <div
        style={{
          maxHeight: "120vh",
          overflow: "scroll",
          scrollBehavior: "smooth",
        }}  
      >
        <Card style={{ width: "100%" }}>
          <br />
          <Form onFinish={onFinish} form={form}>
            <Row gutter={[20, 20]}>
              <Col md={10}>
                <label className="newsFormLabels">Name</label>
                <Form.Item name={"name"}
                rules={[{ required: true, message: 'Name is required' }]}
                >
                  <TextArea />
                </Form.Item>

                <label className="newsFormLabels">Category</label>
                <Form.Item
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: "Please select Categories!",
                    },
                  ]}
                >
                  <Select size="large">
                    {category?.length > 0 &&
                      category?.map((item: any, index: any) => {
                        return (
                          <Select.Option key={index} value={item.id}>
                            {item?.category}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Row gutter={[20, 20]}>
                  <Col md={12}>
                    <label className="newsFormLabels">Price</label>
                    <Form.Item  name={"prize"}
          rules={[{ required: true, message: 'Price is required' }]}
                    
                    >
                      <InputNumber
                        controls={false}
                        style={{ width: "100%", height: "40px" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <label className="newsFormLabels">End Date</label>
                    <Form.Item name={"end_date"}
                    rules={[{ required: true, message: 'End Date is required' }]}
                    >
                      <DatePicker
                        showTime
                        mode="date"
                        size="large"
                        style={{ width: "100%" }}
                        onChange={handleDateChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <label className="newsFormLabels">Discription</label>
                <Form.Item name={"description"}>
                  <TextArea />
                </Form.Item>
                <label className="newsFormLabels">Location</label>
                <Form.Item name={"location"}>
                  <TextArea />
                </Form.Item>
              </Col>
              <Col md={10}>
                <PagesList
                  pages={pages}
                  uploadIndx={currentUploadIndex}
                  onChagnge={(value: any) => onchange(value)}
                  imageUploade={(value: any) => uploadeImage(value)}
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={15}></Col>
              <Col md={6}>
                <Button
                  className="SubmitButton"
                  loading={isLoading}
                  type="default"
                  htmlType="submit"
                  style={{ height: "40px", width: "100%" }}
                >
                  {Data?.id ? `Update` : `Submit`}
                </Button>

              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
}

export default AuctionNewForm;






