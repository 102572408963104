import { Col, Container, Row } from "react-bootstrap";
import "./styles.scss";
import { useEffect, useState } from "react";
import { API } from "../../../config/api";
import { Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import { GET } from "../../../utils/apiCalls";
import arrow2 from "../../../assets/images/newarr.svg";
export default function BidBYCategory() {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [data, setData] = useState<any>();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const url = API.GET_CATEGORY_BY_ONEAUCTION;
      let res: any = await GET(url, null);
      if (res?.status) {
        setData(res?.data);
        setIsloading(false);
      }
    } catch (error) {
      console.log("=error=", error);
      setIsloading(false);
    }
  };
  return (
    <>
      <div className="CategoryWise_Container">
        <Container>
          {isLoading ? (
            <Skeleton active />
          ) : (
            <>
              <div className="CategoryWise_Containerview">
                Auctions by category
              </div>
              <div className="CategoryWise_Box">
                <Row>
                  {data?.map((item: any, index: any) => {
                    return (
                      <>
                        {item?.auctions?.length ? (
                          <Col md={index === 4 || index === 10 ? 8 : 4} xs={12}>
                            <div
                            
                              className="CategoryWise_Box1"
                            >
                              <div
                               
                                className="CategoryWise_Box2"
                              >
                                <img src={item?.auctions?.[0].images?.[0]} />
                                <div onClick={() =>
                                        navigate(`listingAuctions/${item?.id}`)
                                      } className="CategoryWise_Box3">
                                  <div  className="CategoryWise_Box4">
                        
                                    {item?.category}
                                  </div>

                          
                                    <img
                                      src={arrow2}
                                      onClick={() =>
                                        navigate(`listingAuctions/${item?.id}`)
                                      }
                                    />
                   
                                </div>
                              </div>
                            </div>
                          </Col>
                        ) : null}
                      </>
                    );
                  })}
                </Row>
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
}
