import React from "react";
import "./styles.scss";
import { Container } from "react-bootstrap";
import Header from "../header";
import Footer from "../footer";
function Privacy() {
  return (
    <>

      <div className="privacyScreeen pt-4">
      <Header/>
        <Container>
          <h3 className="headline">Privacy Policy</h3>
          <div className="common-text">
            At Lucky Star Gold, we value your trust and are committed to
            maintaining the privacy and security of your personal information.
            This Privacy Policy outlines how we collect, use, and safeguard the
            information you provide to us when you visit our website.
          </div>
          <h3 className="headline">Changes to the Privacy Policy</h3>
          <div className="common-text">
            Please note that this Privacy Policy may be subject to changes and
            updates without prior notice. We encourage you to review this policy
            periodically to stay informed about how we protect your privacy.
          </div>
          <h3 className="headline">Collection of Personal Information:</h3>
          <div className="common-text">
            When you create an account with luckystargold.com, we collect
            personal information such as your name, email address, contact
            number, and other relevant details only with the consent of the
            customer. This information is used for setting up your account with
            your consent and enabling you to place orders. Additionally, we may
            use your contact details to notify you about exclusive offers based
            on your previous orders and interests.
          </div>
          <h3 className="headline">Use of Information:</h3>
          <div className="common-text">
            We use the personal information you provide to fulfill your service
            requests. This includes troubleshooting problems, conducting
            surveys, and delivering information about our latest offers. We may
            also analyze demographic and profile data to better understand user
            activity on our website. Furthermore, we utilize IP addresses to
            diagnose any technical issues that may arise.
          </div>
          <h3 className="headline">Disclosure of Personal Data:</h3>
          <div className="common-text">
            <div>
            <strong className="privacy-subtext">a.</strong> To enhance and facilitate our services, we may engage the
              services of third-party entities and individuals. These parties
              may assist us with tasks such as maintenance, analysis, audit,
              marketing, and development. Rest assured, these third parties are
              granted limited access to your information solely for the purpose
              of carrying out these tasks on behalf of Lucky Star Gold. We
              ensure that they adhere to strict confidentiality terms and comply
              with data protection regulations.
            </div>
            <br />
            <div>
            <strong className="privacy-subtext">b.</strong> When using our application, you will be required to acknowledge
              and accept the third-party developer user agreement as well as the
              Lucky Star Gold privacy policy.
            </div>
            <br />
            <div>
            <strong className="privacy-subtext">c.</strong> There may be circumstances where we need to disclose your
              information to other parties in order to comply with legal
              requirements, respond to mandatory legal processes, verify, or
              enforce compliance with our service policies, or safeguard the
              rights, property, or safety of Lucky Star Gold, our affiliates,
              business partners, or customers. These disclosures are made in
              accordance with applicable laws and regulations, with the utmost
              priority given to protecting your privacy.
            </div>
          </div>
          <h3 className="headline">Security Precautions:</h3>
          <div className="common-text">
            We have implemented strict security measures to protect your
            information from loss, alteration, and misuse. Our website utilizes
            secure servers to store and safeguard your personal account
            information. Once your information is in our possession, we adhere
            to stringent security guidelines to prevent unauthorized access.
          </div>
          <h3 className="headline"> Choice/Opt-Out:</h3>
          <div className="common-text">
            luckystargold.com provides users with the option to opt-out of
            promotional and marketing-related services from us and our partners
            after setting up an account. You can manage your communication
            preferences by accessing your account settings.
          </div>
          <h4>Links to Other Websites:</h4>
          <div className="common-text">
            Our website may contain links to external websites. Please be aware
            thatLucky Star Gold is not responsible for the privacy practices or
            content of these third-party websites. When you access these
            external platforms, their respective privacy policies and terms of
            use will apply. We recommend reviewing the policies of any website
            or application you visit to understand your rights and obligations.
            If you have any concerns or questions, please contact the owner or
            operator of the specific website or application. At Lucky Star Gold,
            we prioritize the protection of your privacy. If you have any
            concerns or questions regarding the handling of your personal data,
            please don't hesitate to contact us at sales@luckystargold.com.
          </div>
          <div className="pb-4"></div>
        </Container>
      </div>
<Footer/>
    </>
  );
}
export default Privacy;