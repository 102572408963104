import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./styles.scss";
import { FaBookmark } from "react-icons/fa6";

import {
  Button,
  Carousel,
  Tooltip,
  message,
  notification,
} from "antd";
import CountDown from "./components/countDown";
import { RiAuctionFill } from "react-icons/ri";
import { RxLapTimer } from "react-icons/rx";
import { useSelector } from "react-redux";
import BidModal from "../bidModal";
import { API } from "../../config/api";
import { DELETE, GET, POST } from "../../utils/apiCalls";

function Detail(props: any) {
  const [Notifications, contextHolder] = notification.useNotification();
  const { data } = props;
  const [countDownArray, setCountDownArray] = useState<any>();
  const [showBid, setShowBid] = useState<any>(false);
  const [saved, setSaved] = useState<any>(false);
  const [isloading, setIsloading] = useState<any>(true);
  const [savedId, setSavedId] = useState<any>();

  const User = useSelector((state: any) => state.User.user);

  useEffect(() => {
    const timer = setInterval(() => {
      let timeObj: any = {};

      if (data) {
        timeObj = calculateTimeRemaining(data?.enddate);

        setCountDownArray(timeObj);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (data?.id) {
      FaveByItem(data?.id);
    }
  }, []);

  const FaveByItem = async (id: any) => {
    let url = API.GET_BY_ITEM + `/${id}`;
    const response: any = await GET(url, null);
    if (response?.status === true) {
      if (response?.data?.UserId === User.id) {
        setSavedId(response?.data?.id);
        setSaved(true);
      }
    }
  };

  const calculateTimeRemaining = (endDate: any) => {
    const difference = new Date(endDate).getTime() - new Date().getTime();
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    setIsloading(false);
    return { days, hours, minutes, seconds };
  };
  const saveToFav = async () => {
    try {
      if (!User.id) {
        Notifications["info"]({
          message: "Info",
          description: `please log in to Add favorite`,
        });
        return;
      } else {
        let url = API.CREATE_FAVOURITE;
        let obj = {
          itemId: Number(data?.id),
          UserId: Number(User.id),
          issaved: true,
        };
        const response: any = await POST(url, obj);
        if (response?.status === true) {
          message.success("Added to Favorite");
          setSavedId(response?.data?.id);
          setSaved(true);
        } else {
          Notifications["info"]({
            message: "Info",
            description: `Problem while Adding to favorite`,
          });
        }
      }
    } catch (erro) {
      console.log(erro);
    }
  };

  const RemoveFromFav = async () => {
    try {
      let url = API.DELETE_FAVOURITE + `/${savedId}`;
      const response: any = await DELETE(url);
      if (response?.status === true) {
        message.success("Removed from Favorite");
        setSavedId("");
        setSaved(false);
      } else {
        message.error("Problem while Remove from favorite");
      }
    } catch (erro) {
      console.log(erro);
    }
  };
  const bidError = () => {
    setShowBid(false);

    notification.warning({
      message: "Please login to place bid.",
      placement: "topRight", 
    });
  };
  return (
    <Row style={{ marginTop: "20px" }}>
      {contextHolder}
      <Col md={6} xs={12} className="order-2 order-sm-1">
        <div className="Detail_Box1">
          <div className="Detail_Box5" style={{ color: " #000" }}>
            {data?.name}
          </div>
          <div className="Detail_BoxDesc">{data?.location}</div>
          <div className="Detail_BoxDesc">{data?.description}</div>
          <br />
          <div
            className="Detail_Box5"
            style={{ color: "#000" }}
          >{`$${data?.price} INR`}</div>
          <hr />
          <div className="Detail_Box6">
            <div className="Detail_Box6Box">
              <div className="Detail_Box6Head">
                <RiAuctionFill color="gray" size={25} />
                <span style={{ marginLeft: "10px" }}>Bids</span>
              </div>
              <div style={{ fontFamily: "DMSans-SemiBold", color: "gray" }}>
                {props?.count ? props?.count : "0"}
              </div>
            </div>
            <div className="Detail_Box6Box">
              <div className="Detail_Box6Head">
                <RxLapTimer color="gray" size={25} />
                <span style={{ marginLeft: "10px" }}>Time Remaining</span>
              </div>
              {!isloading ? (
                <div
                  style={{ fontFamily: "DMSans-SemiBold", color: "gray" }}
                >{`${countDownArray?.days}Days:${countDownArray?.hours}h${countDownArray?.minutes}m${countDownArray?.seconds}s`}</div>
              ) : null}
            </div>
          </div>
          <br />
          <Button
            style={{
              height: "50px",
              background: "#181312",
              color: "#fff",
              border: "none",
            }}
            onClick={() => {
              User?.id ? setShowBid(true) : bidError();
            }}
          >
            Place a Bid
          </Button>
        </div>
      </Col>
      <Col md={6} xs={12} className="order-1 order-sm-2">
        <div className="Detail_Box">
          <Carousel
            effect="fade"
            dotPosition="bottom"
            style={{
              alignSelf: "center",
              position: "relative",
              
            }}
            
          >
            {data?.images.map((item: any) => (
              <div className="Detail_Box2">
                <img src={item} />
                <CountDown data={data} />
              </div>
            ))}
          </Carousel>
          <div className="Detail_Box3">
            <div className="Detail_Box4">
              <div className="Detail_faveicon">
                {saved ? (
                  <Tooltip placement="bottom" title={"Remove Favorite"}>
                    <FaBookmark color="orange" onClick={RemoveFromFav} />
                  </Tooltip>
                ) : (
                  <Tooltip placement="bottom" title={"Add to Favorite"}>
                    <FaBookmark color="#fff" onClick={saveToFav} />
                  </Tooltip>
                )}
              </div>
              {/* <div className="Detail_faveicon">
                <TfiMoreAlt color="#fff" />
              </div> */}
            </div>
          </div>
        </div>
      </Col>

      {showBid ? (
        <BidModal
          visible={showBid}
          data={data}
          userId={User?.id}
          close={() => setShowBid(false)}
          refresh={() => props?.refresh()}
        />
      ) : null}
    </Row>
  );
}

export default Detail;
