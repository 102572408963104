import React from "react";
import { LuUserCircle } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/slice/userSlice";
import { notification } from "antd";
const PopoverItem = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    notification.success({
      message: "Logout Successful",
      description: "You have been logged out successfully.",
      placement: "topRight", 
    });
    navigate(`/`);
  };
  return (
    <div className="Profile-popover">
      <div className="Popover-item1">
        <LuUserCircle size={28} />
        <div className="Popover-txt1">ADMIN</div>
      </div>

      <div
        className="Popover-item3"
     onClick={handleLogout}
      >
        Logout
      </div>
    </div>
  );
};

export default PopoverItem;
