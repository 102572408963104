import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./styles.scss";
import { useParams } from "react-router-dom";
import { API } from "../../config/api";
import { POST } from "../../utils/apiCalls";
import { Skeleton } from "antd";
import ItemCard from "./components/itemCard";
import Header from "../../components/header";
import { useSelector } from "react-redux";
import Footer from "../../components/footer";

function ItemListing() {
  const User = useSelector((state: any) => state.User.user);
  const { id } = useParams();
  const [isLoading, setIsloading] = useState(true);
  const [data, setData] = useState<any>();

  useEffect(() => {
    loadData(1, 20);
  }, []);

  const loadData = async (page: any, take: any) => {
    try {
      const url = API.LIST_AUCTIONBY_CAT;

      let obj = {
        page: page,
        take: take,
        id: id ? id : "",
      };

      let response: any = await POST(url, obj);
      if (response) {
        setData(response.data);
        setIsloading(false);
      } else {
        console.log("error");
        setIsloading(false);
      }
    } catch (error) {
      console.log("=error=", error);
      setIsloading(false);
    }
  };
  return (
    <div className="itemListBox">
      <Container fluid={false}>
        <Header />

        <br />
        {isLoading ? <Skeleton active /> : <ItemCard data={data} />}
      </Container>
      <Footer/>
    </div>
  );
}

export default ItemListing;
