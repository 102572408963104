import React from "react";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import star from "../../../assets/images/5star.png";

function Reviews() {
  const Reviews = [
    {
      review:
        "I recently began using auctions for buying and selling, and I'm extremely satisfied with the experience. The platform’s wallet-based purchasing system is both convenient and secure, ensuring smooth, worry-free transactions.",
      auther: "Emily Davis",
    },
    {
      review:
        "Bidding on items is generally seamless, though I've experienced occasional glitches where my bids didn't register properly, which can be frustrating during competitive auctions. Additionally, the auction countdown timer could be more precise, as I've noticed slight discrepancies in the remaining time displayed.",
      auther: " David Bennett",
    },
  ];
  return (
    <div className="Review_Container">
      <Container fluid={false}>
        <div className="Review_Box1">Customer Reviews</div>
        <Row>
          {Reviews?.map((item: any) => {
            return (
              <>
                <Col md={6} xs={12}>
                  <img className="Review_IMG" src={star} />
                  <div className="Review_Box2">{item.review}</div>
                  <br />
                  <div className="Review_Box3">{item.auther}</div>
                </Col>
              </>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default Reviews;
