import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../components/header";
import "./styles.scss";
import Contact from "./components/contact";
import Footer from "../../components/footer";

const Contactus = () => {
  return (
    <div className="Contactus_Box1">
      <Container>
        <Header />
        <Contact />
      </Container>
      <br/>
      <br/>
      <Footer/>
    </div>
  );
};

export default Contactus;
