import { Route, Routes } from "react-router-dom";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import ProtectedRoute from "./utils/protectRouter";
import { useSelector } from "react-redux";
import "./App.scss";

import Home from "./website/home";
import Login from "./website/login";
import Signup from "./website/signup";
import Search from "./website/search";
import Auctiondetails from "./website/auctiondetails";
import Contactus from "./website/contactus";
import Help from "./website/help";
import Favourites from "../src/users/favourites";

import UserRoutes from "./users/routes";

import Navigation from "./admin/route";
import ChangePassword from "./users/changePassword";
import ItemListing from "./website/itemListing";
import AboutUs from "./website/aboutUs/aboutus";
import ChangPassword from "./website/forgotPassword";
import WalletScreen from "./website/walletScreen";
// import Privacy from "./components/Privacy /Index";
import About from "./website/home/about";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";

function App() {
  const { user, admin, usr } = useSelector((state: any) => state.User);
  return (
    <I18nextProvider i18n={i18n}>
      <Routes>
        <Route element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/search/:cat/:query/:date" element={<Search />} />
        <Route path="/details/:id" element={<Auctiondetails />} />
        <Route path="/listingAuctions/:id" element={<ItemListing />} />
        <Route path="/listingAuctions" element={<ItemListing />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/changePassword" element={<ChangPassword />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/help" element={<Help />} />
        <Route path="/Wallet" element={<WalletScreen />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/about" element={<About />} />
        <Route path="/Terms" element={< Terms/>} />

        <Route path="r4xcvhbgvfcxmfrl̥tPassword" element={<ChangePassword />} />


        <Route
          path="/usr/*"
          element={
            <ProtectedRoute isSignedIn={usr}>
              <UserRoutes />
            </ProtectedRoute>
          }
        />
        
        <Route
          path="/Auth/*"
          element={
            <ProtectedRoute isSignedIn={admin}>
              <Navigation />
            </ProtectedRoute>
          }
        />
      </Routes>
    </I18nextProvider>
  );
}
export default App;
