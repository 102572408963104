import { Button, Form, Input, Modal, Select } from "antd";
import { Row, Col } from "react-bootstrap";





import React, { useEffect, useState } from 'react';
import "./styles.scss"
import logo from "../../assets/images/logo.png"
import {  PUT } from "../../utils/apiCalls";
export default function UpdateProfile(props: any) {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const Profile = () => {
    const [form] = Form.useForm();
    useEffect(() => {
      // getStaff();
    }, [])
    useEffect(() => {
      form.setFieldsValue({
        name: props?.data?.name,
        address: props?.data?.address,
        national: props?.data?.nationality,
        code: props?.data?.code,
        phoneNo: props?.data?.phoneNo,
        email: props?.data?.email,
        password: props?.data?.password,
      });
    }, [props]);
    const onFinish = async (val: any) => {
      try {
        let UpdatUlrl = "user/" + props?.id;
        let obj = {
          name: val?.name,
          address: val?.address,
          nationality: val?.national,
          code: val?.code,
          phoneNo: parseInt(val?.phoneNo),
          password: val?.password,
          email: val.email,
        };
        console.log("=====obj===", obj);
        const response = await PUT(UpdatUlrl, obj);

        console.log("===response===", response);
      } catch (err: any) {
        console.log("error====", err);
 
      }
    };
    return (
      <main>
        <Row>
          <Col md={12}>
            <div className="Profile-Box1">
              <div
                style={{
                  fontSize: "22px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img style={{ width: "30%" }} src={logo} />
              </div>
              <br />
              <Form form={form} onFinish={onFinish}>
                <Row>
                  <Col md={6}>
                    <label className="newsFormLabels"> Full Name</label>
                    <Form.Item name={"name"}>
                      <Input className="Profile-input" size="large" />
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <label className="newsFormLabels"> Email Address</label>
                    <Form.Item name={"email"}>
                      <Input className="Profile-input" size="large" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <label className="newsFormLabels">Nationality</label>
                    <Form.Item name={"national"}>
                      <Select
                        className="Profile-input"
                        style={{ background: "transparent" }}
                      >
                        <Select.Option value={"India"}>India</Select.Option>
                        <Select.Option value={"Soudi"}>Soudi</Select.Option>
                        <Select.Option value={"qatar"}>qatar</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <label className="newsFormLabels">Phone Numbe</label>
                    <Form.Item name={"phoneNo"}>
                      <Input
                        className="Profile-input"
                        placeholder="Phone...."
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <label className="newsFormLabels">Address</label>
                    <Form.Item name={"address"}>
                      <Input
                        className="Profile-input"
                        placeholder="Address...."
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <label className="newsFormLabels">Password</label>
                    <Form.Item name={"password"}>
                      <Input.Password className="Profile-input" />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="Profile-BtnBox">
                  <Button
                    style={{ width: "100%", height: "40px" }}
                    htmlType="submit"
                    type="primary"
                  >
                    submit
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </main>
    );
  };
  return (
    <Modal
      open={props.visible}
      onCancel={() => props.close()}
      width={600}
      footer={false}
      centered
    >
      <br />
      <Profile />
    </Modal>
  );
}