import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Upload,
  message,
  Modal,
  UploadProps,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import "../styles.scss";
import { FiUploadCloud } from "react-icons/fi";
import { BsArrowLeftCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { API } from "../../../config/api";
import { Container } from "react-bootstrap";
import PageHeader from "../../route/pageHeader";
import { POST } from "../../../utils/apiCalls";

function BannerNewForm(props: any) {
  const { data: propsData, visible, close } = props;

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(propsData?.image);

  const onFinish = async (val: any) => {
    try {
      let CreateUrl = API.CREATE_BANNER;
      let UpdatUlrl = API.UPDATE_BANNER;
      let obj = {
        title: val?.title || "",
        description: val?.description || "",
        position: val?.position || "",
        featured: val?.featured || true,
        image: imageUrl,
      };
      let UpdateObj = {
        title: val?.title || "",
        description: val?.description || "",
        position: val?.position || "",
        featured: val?.featured || true,
        id: Number(propsData?.id),
        image: imageUrl,
      };
      let response: any = await POST(
        propsData?.id ? UpdatUlrl : CreateUrl,
        propsData?.id ? UpdateObj : obj
      );
      if (response) {
        message.success("successfull");
        props.refresh();
        props.close();
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageUpload = async (info: any) => {
    setIsLoading(true);
    const file = info?.file?.originFileObj;
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(
        API.BASE_URL + API.COMPRESS_IMAGE,
        formData
      );
      if (response && response.data?.Location) {
        setImageUrl(response.data.Location);
        message.success("Image uploaded successfully.");
      } else {
        message.error("Failed to upload image. Please try again.");
      }
    } catch (error) {
      message.error("Failed to upload image. Please try again.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const propss: UploadProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    async onChange(info) {
      const { status } = info.file;
      setIsLoading(true);
      if (status !== "uploading") {
        const result: any = await handleImageUpload(info);
        if (result) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <Modal
      visible={visible}
      onCancel={close} // Fix: Use `close` function to properly close the modal
      width={800}
      footer={false}
      centered
    >
      <PageHeader
        title={propsData?.id ? `Update Banner` : `Create Banner`}
        bredcume={"Banner"}
      />
      <br />
      <Form form={form} onFinish={onFinish} initialValues={{ ...propsData }}>
        <Container>
          <Row gutter={[20, 20]}>
            <Col md={12}>
              <label className="newsFormLabels">Title</label>
              <Form.Item name="title">
                <TextArea />
              </Form.Item>
              <label className="newsFormLabels">Banner</label>
              <Form.Item name="image"
              rules={[
                {
                  required: true,
                  message: "Please upload a banner image!",
                },
              ]}
              >
                <Upload {...propss}>
                  <div className="imageBox1">
                    {imageUrl ? (
                      <>
                        {isLoading ? (
                          <div>Loading . . .</div>
                        ) : (
                          <img src={imageUrl} className="imageBox2" />
                        )}
                      </>
                    ) : (
                      <>
                        <FiUploadCloud size={30} />
                        <div>Upload Banner  </div>
                        <div>Size 3 MB</div>
                      </>
                    )}
                  </div>
                </Upload>
              </Form.Item>
            </Col>
            <Col md={12}>
              <label className="newsFormLabels">Description</label>
              <Form.Item name="description">
                <TextArea />
              </Form.Item>
              <label className="newsFormLabels">Position</label>
              <Form.Item name="position"
              
              rules={[{ required: true, message: 'Position is required' }]}>
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Container>

        <hr />
        <Row gutter={[20, 20]}>
          <Col md={12}></Col>
          <Col md={12}>
            <Button
              className="SubmitButton"
              type="default"
              htmlType="submit"
              style={{ height: "50px", width: "100%" }}
            >
              {propsData?.id ? `Update` : `Submit`}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default BannerNewForm;
