import React, { useState } from "react";
import "./styles.scss";
import { Button,  Form, Input, notification } from "antd";
import card1 from "../../assets/images/coinone.png";
import card2 from "../../assets/images/coinsecond.png";
import card3 from "../../assets/images/cointhree.png";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { addFunds } from "../../redux/slice/walletSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { POST } from "../../utils/apiCalls";
import { API } from "../../config/api";
import Header from "../../components/header";
import {  Container  } from "react-bootstrap";
import Footer from "../../components/footer";
declare global {
  interface Window {
    Razorpay: any;
  }
}
function WalletScreen() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Notifications, contextHolder] = notification.useNotification();
  const User = useSelector((state: any) => state.User.user);
  const [HoverButton, setHoverButton] = useState(false);

  const onFinish = (val: any) => {
    try {
      if (val?.price) {
        var options = {
          key: "rzp_test_qrZersLsStO5Ja",
          key_secret: "fYjQFQvEzX0nRNBnYi0SWTLa",
          amount: val?.price * 100,
          currency: "INR",
          name: "Auction",
          handler: async (response: any) => {
            if (response) {
              try {
                const myHeaders = new Headers();
                myHeaders.append(
                  "Authorization",
                  "Basic cnpwX3Rlc3RfUVppT2JJZDB6dXJQSlQ6b0Y2WW5PSUNPM2lzOUg1TEg5NjlDdUNu"
                );
                let requestOptions: any = {
                  method: "GET",
                  headers: myHeaders,
                  redirect: "follow",
                };
                fetch(
                  `https://api.razorpay.com/v1/payments/${response.razorpay_payment_id}`,
                  requestOptions
                )
                  .then((result) =>
                    console.log("========result=======", result)
                  )
                  .catch((error) => console.error(error));
              } catch (error) {}
              if (location?.state?.mode === "initialDeposit") {
                let obj = {
                  type: "initialDeposit",
                  transactionId: response.razorpay_payment_id,
                  amount: Number(val?.price),
                  date: new Date().toISOString(),
                };
                await createWallet(obj);
              } else if (location?.state?.mode === "createWallet") {
                dispatch(
                  addFunds({
                    amount: Number(val?.price),
                    id: response?.razorpay_payment_id,
                  })
                );
                navigate(-1);
              } else if (location?.state?.mode === "Deposit") {
                let obj = {
                  type: "Deposit",
                  transactionId: response.razorpay_payment_id,
                  amount: Number(val?.price),
                  date: new Date().toISOString(),
                };
                await UpdateWallet(location?.state?.id, obj);
              }
            }
            alert(response.razorpay_payment_id);
          },
          notes: {
            address: "Razorpay Corporate office",
          },
          theme: {
            color: "#9f5f2d",
          },
        };
        var pay: any = window.Razorpay(options);
        pay.open();
      } else {
        Notifications["info"]({
          message: "Info",
          description: `Problem while Adding to favorite`,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const createWallet = async (val: any) => {
    let body = {
      wallet_Amount: val?.amount,
      user_ID: Number(User.id),
      payment_details: val,
    };
    let url = API.CREATE_WALLET;
    try {
      const response: any = await POST(url, body);
      if (response) {
        isertTransaction(response);
      }
    } catch (error) {
      console.log("=======Error=====", error);
    }
  };
  const UpdateWallet = async (val: any, obj: any) => {
    let body = {
      id: Number(val),
      wallet_Amount: Number(location?.state?.amount) + Number(obj?.amount),
      payment_details: obj,
    };
    let url = API.UPDATE_WALLET;
    try {
      const response: any = await POST(url, body);
      if (response) {
        await isertTransaction(response);
      }
    } catch (error) {
      console.log("=======Error=====", error);
    }
  };
  const isertTransaction = async (val: any) => {
    let body = {
      mode: val?.data?.type || val?.data?.payment_details?.type,
      wallet_ID: Number(val?.data?.id),
      user_ID: Number(val?.data?.user_ID),
      transaction_details: val?.data?.payment_details,
    };
    let url = API.CREATE_TRANSACTION;
    try {
      const response: any = await POST(url, body);
      if (response.status) {
        navigate("/usr/transactions");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    
    <div className="WalletScreen_Box">

      <Container>
        <Header />
        <div className="WalletScreen_Box1">
          {contextHolder}

          <div className="WalletScreen_Box2">
            <div className="WalletScreen_Box3">
              <img src={card3} />
            </div>
            <div className="WalletScreen_Box4">
              {" "}
              Create
              <br /> payment requests
            </div>
            <div className="WalletScreen_Box5">
              <img src={card1} />
              <div className="WalletScreen_Box6">
                Make a simple payment <br />
                Use our powerful features to customize your request
              </div>
              <img src={card2} />
            </div>
          </div>
          <Form onFinish={onFinish}>
            <div className="WalletScreen_Box7">
              <div className="WalletScreen_Box8">
                <div className="WalletScreen_Box10">
                  <Form.Item
                    name="price"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Amount to the wallet",
                      },
                    ]}
                  >
                    <Input
                      size="middle"
                      bordered={false}
                      placeholder="Enter Amount"
                      style={{
                        borderBottom: "none",
                        borderRadius: "0px",
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <br />
            <div className="WalletScreen_Box9">
              <div
                className={
                  HoverButton
                    ? "WalletScreen_Box9Hover"
                    : "WalletScreen_Box9WithoutHover"
                }
              >
                <Button
                  className="WalletButtonDiv2"
                  // loading={loading}
                  htmlType="submit"
                  onMouseEnter={() => setHoverButton(true)}
                  onMouseLeave={() => setHoverButton(false)}
                >
                  <IoIosArrowRoundForward size={25} color="#000" />
                </Button>
                <div
                  className={
                    HoverButton ? "ButtonDiv1WalletHover" : "ButtonDiv1Wallet"
                  }
                >
                  pay
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Container>
      <Footer/>
    </div>
  );
}

export default WalletScreen;
