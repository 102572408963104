import React from "react";
import countryCode from "../../assets/data/countryCode.json";
import Prefixselector from "../../assets/data/prefixSelector/prefixselector";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { POST } from "../../utils/apiCalls";
import { Button, Card, Form, Input, Select, message } from "antd";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowRoundForward } from "react-icons/io";
import { API } from "../../config/api";
import discription from "../../assets/images/loginbackground.png";
import { useDispatch, useSelector } from "react-redux";
import { MdCurrencyRupee } from "react-icons/md";
import { clearWallet } from "../../redux/slice/walletSlice";
import { CiWallet } from "react-icons/ci";
import { Spin } from 'antd';

function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const balance = useSelector((state: any) => state.Wallet.balance);
  const transactions = useSelector((state: any) => state.Wallet.transactions);

  const onFinish = async (val: any) => {
    let body = {
      name: val?.fullname,
      nationality: val?.national,
      password: val?.password,
      email: val?.email,
      role: "User",
      address: val?.national,
      phoneNo: val?.code + val?.phone,
    };
    let url = API.CREATE_USER;
    try {
      setLoading(true);
      const response: any = await POST(url, body);
      if (response?.status) {
        navigate("/login");
        message.success("Sign up success");
        createWallet(response?.data?.id);
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const setWallet = () => {
    navigate("/Wallet", {
      state: { title: "Create your wallet", mode: "createWallet" },
    });
  };

  const createWallet = async (val: any) => {
    let body = {
      wallet_Amount: balance,
      user_ID: Number(val),
      payment_details: transactions,
    };
    let url = API.CREATE_WALLET;
    try {
      setLoading(true);
      const response: any = await POST(url, body);
      if (response) {
        isertTransaction(response);
        dispatch(clearWallet());
      }
    } catch (error) {
      console.log("=======Error=====", error);
    }
  };
  const isertTransaction = async (val: any) => {
    let body = {
      mode: "initialDeposit",
      wallet_ID: Number(val?.data?.id),
      user_ID: Number(val?.data?.user_ID),
      transaction_details: val?.data?.payment_details,
    };

    let url = API.CREATE_TRANSACTION;
    try {
      setLoading(true);
      const response: any = await POST(url, body);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="signUp-Box1">
        <Container fluid={false}>
          <Row className="g-5">
            <Col md={6} xs={12}>
              <div className="signup_Box2Ipad">
                <img className="signup_Box3IMG" src={discription} />
              </div>
            </Col>
            <Col md={6} xs={0}>
              <div className="sign_Box1">
                <Card className="sign_Box3">
                  <div className="SignUp-head">Sign Up</div>
                  <div>
                    <Form onFinish={onFinish}>
                      <Form.Item
                        name="fullname"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Email",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          bordered={false}
                          placeholder="Full Name"
                          style={{
                            borderBottom: "1px solid #211915",
                            borderRadius: "0px",
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Email",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          type="email"
                          placeholder="Email"
                          bordered={false}
                          style={{
                            borderBottom: "1px solid #211915",
                            borderRadius: "0px",
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name={"national"}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Email",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          placeholder={"United Arab Emirates"}
                          showSearch
                          bordered={false}
                          style={{
                            borderBottom: "1px solid #211915",
                            borderRadius: "0px",
                          }}
                        >
                          {countryCode?.map((item: any, index: any) => {
                            return (
                              <Select.Option key={index} value={item.name}>
                                {item?.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: " your phone number!",
                          },
                        ]}
                      >
                        <Input
                          addonBefore={<Prefixselector />}
                          size="large"
                          placeholder="Enter Phone Number"
                          type="number"
                          bordered={false}
                          inputMode="numeric"
                          pattern="[0-9]*"
                          style={{
                            borderBottom: "1px solid #211915",
                            borderRadius: "0px",
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Enter your Passwod!",
                          },
                        ]}
                        name={"password"}
                        hasFeedback
                      >
                        <Input.Password
                          size="large"
                          bordered={false}
                          placeholder="Password"
                          style={{
                            borderBottom: "1px solid #211915",
                            borderRadius: "0px",
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "The password that you entered do not match!"
                                )
                              );
                            },
                          }),
                        ]}
                        hasFeedback
                        name={"cnfrm-password"}
                      >
                        <Input.Password
                          size="large"
                          bordered={false}
                          placeholder="Confirm Password"
                          style={{
                            borderBottom: "1px solid #211915",
                            borderRadius: "0px",
                          }}
                        />
                      </Form.Item>
                      {balance > 0 ? (
                        <>
                          <div className="sign_Box4">
                            {`Your Wallet Balance : ${balance}`}
                            <MdCurrencyRupee size={20} />
                          </div>
                        </>
                      ) : (
                        <div className="sign_Box4-1" onClick={() => setWallet()}>
                          <CiWallet size={25} />
                          &nbsp; &nbsp; Create Your Wallet
                        </div>
                      )}

                      <div className="ButtonDiv">
                        <div
                          className="ButtonDiv1"
                          style={{ color: "#9f5f2d" }}
                        >
                          submit
                        </div>
                        <Button
                          className="ButtonDiv2"
                          loading={loading}
                          htmlType="submit"
                        >
                          {loading ? <Spin />: false }
                          <IoIosArrowRoundForward size={25} color="#fff" />
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SignUp;
