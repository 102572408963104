import { Button, Form, Input, InputNumber, Modal, Select, message } from "antd";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { useState } from "react";
import "./styles.scss";
import logo from "../../assets/images/logo.png"
export default function UpdatePassword(props: any) {
  const navigate = useNavigate();
  const Password = () => {
    const [showotp, setOTP] = useState(false);
    const [form] = Form.useForm();

    const onFinish = (val: any) => {
      let obj = {
        email: val?.email,
      };
      let verifyObj = {
        email: val?.email,
        otp: val?.otp,
      };

      let url = ` http://localhost:8020/user/changePassword`;
      let url2 = ` http://localhost:8020/user/VerifyOTP`;
      try {
        axios
          .post(val?.otp ? url2 : url, val?.otp ? verifyObj : obj)
          .then((response) => {
            if (response.data.status === true) {
              if (val?.otp) {
                message.success("otp verified successs fully");
                navigate(`/usr/changePassword`);
              } else {
                message.success("otp sended successs fully");
              }
              setOTP(true);
            } else {
              if (val?.otp) {
                message.error("Incorrect otp");
              } else {
                message.error("Incorrect email address");
              }
            }
          });
      } catch (error) {
        console.log("=====error======", error);
        message.error("Oops, somthing went wrong please try after some time ");
      }
    };
    return (
      <main>
        <Row>
          <Col md={12}>
            <div >
              {/* <div>Update password</div> */}
              <div className="UpdatePassword-Box1">
                <img className="UpdatePassword-autctionlogo" src={logo} />
              </div>
              <br />
              <Form form={form} onFinish={onFinish}>
                <label > Email Address</label>
                <Form.Item name={"email"}>
                  <Input size="large"></Input>
                </Form.Item>
                {showotp ? (
                  <>
                    <label >Enter OTP</label>
                    <Form.Item name={"otp"}>
                      <Input.Password />
                    </Form.Item>
                  </>
                ) : null}
                <div className="UpdatePassword-btn-Box" >
                  <Button style={{ width: "70%", height: "40px" }} htmlType="submit" type="primary">

                    submit
                  </Button>
                </div>

              </Form>
            </div>
          </Col>
        </Row>
      </main>
    );
  };
  return (
    <Modal
      open={props.visible}
      onCancel={() => props.close()}
      width={500}
      footer={false}
      centered
    >
      <br />
      <Password />
    </Modal>
  );
}
