import React from "react";
import "./styles.scss";
import { Container } from "react-bootstrap";
import Footer from "../footer";
import Header from "../header";
function Terms() {
  return (
    <>
      <div className="TermsScreeen">
        <Header/>
        <Container>
          <div className="common-hd-terms">Terms & Condition</div>
          <p className="common-text">
            Prior to using the Website, auction.luckystargold.com, owned and operated by
            Auction Gold with its registered office at Auction Gold, please
            carefully read and understand these Terms and Conditions. By
            accessing and using the services offered on our website, you show
            your consent and agreement to be bound by the Terms and Conditions
            stated herein. By accepting these terms and conditions, you agree
            and accept that Auction Gold shall have the right to modify these
            terms and conditions at any time without the obligation to notify
            you, and it is your responsibility to stay updated by checking for
            recent updates. Any revisions to these Terms and Conditions shall
            become effective immediately upon their posting, and you agree to be
            bound by the terms of such updates and amendments.
          </p>
          <div className="common-hd-terms">Eligibility Criteria:</div>
          <p className="common-text">
            Any person who has attained the age of 18 years and above is
            permitted to open an account, use, purchase, or engage in any
            transactions on this Website. A person who is legally incapable of
            entering into contracts as defined by the Indian Contract Act, 1872
            is not eligible to use or conduct transactions through this Website.
            Those who decide to access this Website from outside India are
            responsible for ensuring compliance with applicable local laws to
            the extent that they are relevant. By accepting the terms and
            conditions you hereby agree and accept that you fulfill the
            eligibility criteria above and also accept and agree to abide by the
            laws of India.
          </p>
          <div className="common-hd-terms">Pricing & Payments:</div>
          <p className="common-text">
            The prices displayed on our auction.luckystargold.com website are subject to
            change without prior notice. Please note that you will be charged
            the price listed for the product on the day of your purchase. For
            orders shipped within India, we only accept payment in Indian Rupees
            (INR). The prices indicated already include applicable
            CGST/SGST/IGST. We value your privacy and strive to provide a secure
            and transparent experience when using our website's payment gateway.
            If you are using an international credit/debit card for checkout,
            please note the following:
          </p>
          <p className="common-text">
            {" "}
            <span className="bold-text">1.Currency Conversion: </span>
            During the payment process, the transaction amount will be shown in
            Indian Rupees (INR) before you confirm the payment. To proceed with
            the transaction, you will need to convert the displayed INR amount
            to your local currency, in accordance with your card's policies.
            Please be aware that your credit card issuer may apply currency
            conversion charges, which will be reflected on your statement in
            addition to the product charges on our website.
          </p>
          <p className="common-text">
            {" "}
            <span className="bold-text">2.Identity Verification:</span>For
            certain transactions, we may request a copy of a government-issued
            photo ID from your country of residence. Acceptable proof of
            identification includes a) Passport with Visa Copy, b) Driving
            license, c) Employment card, or d) Any other government-issued ID
            proof. This additional step is essential to ensure the security of
            your transaction and protect against potential fraudulent
            activities. At all times, we handle your personal information with
            the utmost care and comply with applicable data protection laws. For
            detailed information on how we collect, use, and protect your
            personal data, please refer to our comprehensive Privacy Policy.
          </p>
          <div className="common-hd-terms">Pan Card Terms & Conditions:</div>
          <p className="common-text">
            {" "}
            <span className="bold-text"></span>To ensure compliance with
            regulatory requirements, it is mandatory to have PAN issued by the
            Income Tax Department for purchases exceeding Rs. 2 lakhs. Please
            note the terms and conditions regarding PAN card verification:
          </p>
          <p className="common-text">
            {" "}
            <span className="bold-text">1.PAN Card Submission:</span>After
            placing an order, customers will be contacted by our customer
            service executive via phone or email to request the submission of a
            valid PAN card for orders exceeding Rs. 2 lakhs. The customer will
            be provided with instructions on how to submit the PAN card.
          </p>
          <p className="common-text">
            {" "}
            <span className="bold-text">2.Order Verification:</span>Until
            the PAN card is received and verified, the order will be put on
            hold. Our team will review the PAN card details provided by the
            customer for verification purposes.
          </p>
          <p className="common-text">
            {" "}
            <span className="bold-text">3.Name Matching :</span>The
            billing name provided during the order must match the name on the
            PAN card. If there is a discrepancy, the PAN card verification will
            not be successful, and the customer will be notified to rectify the
            mismatch.
          </p>
          <p className="common-text">
            <span className="bold-text">4.Order Confirmation</span>Once
            the PAN card verification is successfully completed and the billing
            name matches the PAN card details, the order will be confirmed, and
            further processing will take place.
          </p>
          <p className="common-text">
            <span className="bold-text">.5 Order Cancellation:</span>If
            the customer fails to provide the PAN card or there is a mismatch in
            the billing name with the PAN card, the order may be canceled. The
            customer will be notified promptly regarding the cancellation and
            provided with further instructions if required. We appreciate your
            understanding and cooperation in complying with these revised PAN
            card terms and conditions. If you have any questions or require
            assistance, please reach out to our customer support team, who will
            be glad to assist you during the PAN card verification process.
          </p>
          <div className="common-hd-terms">Registration:</div>
          <p className="common-text">
            To ensure a seamless and personalized experience, please familiarize
            yourself with the following policy regarding user registration and
            membership on auction.luckystargold.com:
          </p>
          <p className="common-text">
            <span className="bold-text">.1 User Registration:</span>In
            order to complete any transactions on the website, users are
            required to register and become a member of Auction Gold. The
            registration process is essential for account creation and access to
            various features and benefits.
          </p>
          <p className="common-text">
            <span className="bold-text">
              .2 Personal Information and KYC:
            </span>
            During the registration process, users will be prompted to provide
            necessary Personal Information and KYC documents, as defined in our
            Privacy Policy. This includes First Name, Last Name, email address,
            and contact number. The information provided will be used for
            registration purposes and future communication related to
            transactions, as well as any other relevant information that Auction
            Gold may wish to share with customers.
          </p>
          <p className="common-text">
            <span className="bold-text">.3 One-Time Process:</span>
            Registration is a one-time process, ensuring convenience for users
            in future interactions with our website. Once registered, users can
            simply log in or sign in using their existing account credentials
            for subsequent visits.
          </p>
          <p className="common-text">
            <span className="bold-text">
              .4 Privacy and Data Protection:{" "}
            </span>
            We prioritize the security and confidentiality of user information.
            The Personal Information collected during registration is handled in
            accordance with our Privacy Policy, which outlines how we collect,
            use, store, and protect user data. We encourage users to review the
            Privacy Policy for detailed information on data handling practices
            and their rights concerning their personal information. By
            registering and becoming a member, users acknowledge and agree to
            comply with the terms and conditions outlined in our website's User
            Agreement and Privacy Policy. Should you have any questions or
            require assistance regarding the registration process or membership
            benefits, please contact our customer support team, who will be
            happy to assist you.
          </p>
          <div className="common-hd-terms">Gift Cards:</div>
          <p className="common-text">
            The Auction Gold Gift Card(s) can be redeemed across various
            channels, including the Auction Gold website and mobile site. The
            Gift Card(s) can be used to purchase any jewelry products on
            auction.luckystargold.com, except for new Gift Cards and Solitaire. Auction
            Gold will not be responsible in case the card is lost, stolen, or
            misused. The Gift Card(s) are valid for one year from the date of
            issuance and cannot be revalidated thereafter.
          </p>
          <p className="common-text">
            To activate the Gift Card, enter the 16-digit card number and
            6-digit security code (PIN) mentioned on the reverse side of your
            Gift Card. To redeem your active Gift Card(s), simply click on the
            "Gift Card" tab on the payment page and enter the 16-digit Gift Card
            number. It is possible to use multiple Gift Cards in a single
            transaction. Once purchased online, the Gift Card(s) are considered
            redeemed and cannot be canceled, exchanged, or refunded. The Gift
            Card(s) can be purchased only with Indian currency. The Gift Card(s)
            can be partially redeemed multiple times until the balance is
            exhausted, or the card expires. Gift Cards cannot be used to
            purchase another Gift Card. The Gift Cards are void if resold and
            cannot be exchanged for credit or cash. Once expired, they cannot be
            revalidated. In addition to these Terms and Conditions, the use of
            Gift Card(s) is subject to Auction Gold's general Terms of Use. In
            case of any queries, reach us at{" "}
            <a href="mailto:https://auction.luckystargold.com">sales@auction.com</a>{" "}
            or +971 54 581 6161.
          </p>
          <div className="common-hd-terms">Site Security:</div>
          <p className="common-text">
            Users are prohibited from violating or attempting to violate the
            security measures of the Auction Gold website. This includes but is
            not limited to:
          </p>
          <ul>
            <li>
              Unauthorized access of data not intended for the user or logging
              into a server or account without proper authorization.
            </li>
            <li>
              Attempting to probe, scan, or test the vulnerability of systems or
              networks, or breaching security or authentication measures without
              authorized permission.
            </li>
            <li>
              Interfering with the service of other users, hosts, or networks,
              such as submitting viruses, overloading, flooding
            </li>
            <li>
              Sending unsolicited emails, including promotions or advertisements
              for products or services.
            </li>
            <li>
              Forgery of TCP/IP packet headers or any part of header information
              in emails or newsgroup postings. Violations of system or network
              security may result in civil or criminal liability.
            </li>
          </ul>
          <p className="common-text">
            Auction Gold will investigate any such violations and may cooperate
            with law enforcement authorities in prosecuting users involved in
            such activities. Users agree not to use any device, software, or
            routine that interferes or attempts to interfere with the proper
            functioning of the website, or any activities conducted on the site.
            Furthermore, users agree not to use any engine, software, tool,
            agent, or mechanism (including browsers, spiders, robots, avatars,
            or intelligent agents) to navigate or search the site, except for
            the search engine and search agents available on the site or
            commonly available third-party web browsers (such as Microsoft
            Explorer, Google Chrome, Mozilla Firefox).
          </p>
          <div className="common-hd-terms">Fraudulent Transaction:</div>
          <p className="common-text">
            Auction Gold reserves the right to take necessary actions to recover
            the cost of goods, collection charges, damages incurred by Auction
            Gold, and legal expenses from individuals who engage in fraudulent
            activities on the website.Auction Gold also reserves the right to
            initiate legal proceedings against such individuals for their
            fraudulent use of the website or any other unlawful acts that
            violate these Terms and Conditions.
          </p>
          <div className="common-hd-terms">Disclaimer:</div>
          <p className="common-text">
            The Auction Gold website, along with its materials, content,
            products, and services, are provided on an "AS IS" basis without any
            warranties, express or implied, except as expressly stated otherwise
            in writing by Auction Gold, if applicable.Auction Gold disclaims all
            other warranties, including implied warranties of merchantability,
            fitness for a particular purpose, title, and non-infringement,
            regarding the information, content, and materials on the website.
            Auction Gold does not guarantee that the website materials or any
            portion of its services are accurate, complete, reliable, current,
            or free from errors. Auction Gold does not represent or warrant that
            the website or its servers are free from viruses or other harmful
            components. All products and information displayed on the website
            are an invitation to offer. Your purchase order constitutes your
            offer, which is subject to these Terms and Conditions. We reserve
            the right to accept or reject your offer in whole or in part. Our
            acceptance of your order occurs upon the dispatch of the ordered
            product(s). Dispatch may happen at different times for different
            portions of the order. In such cases, the dispatched portion will be
            considered accepted by us, while the remaining balance will continue
            to be an offer, and we retain the right to accept or reject the
            balance. Any action or omission, including but not limited to
            acceptance of purchase amount, by Auction Gold prior to the actual
            dispatch of the ordered product(s) does not constitute acceptance of
            your offer. If you have provided us with your email address, we will
            notify you via email to confirm the receipt of your order, its
            dispatch, and subsequent acceptance. We have made every effort to
            accurately display the colors and sizes of our products on the
            website. However, the actual colors you see may vary depending on
            your monitor or display device. We cannot guarantee that your
            monitor's display will accurately reflect the color of the product
            upon delivery. The packaging of the product may also differ from
            what is displayed on the website. If any part of this website
            disclaimer is deemed invalid by law, the remaining parts remain
            valid and enforceable.
          </p>
          <div className="common-hd-terms">Changes to Site Disclaimer:</div>
          <p className="common-text">
            Auction Gold reserves the right to modify the website disclaimer
            periodically. In the event of any changes, we will inform you by
            posting the updated disclaimer on the website. If you do not agree
            to be bound by the revised disclaimer, we kindly ask you to refrain
            from using the website. The continued use of the website after
            notifying about the updated reminder shall be considered as an
            acceptance of the updated disclaimer.
          </p>
          <div className="common-hd-terms">Website Content:</div>
          <p className="common-text">
            Auction Gold retains all intellectual property rights to the
            content, including text, programs, products, processes, technology,
            images, photographs, videos, illustrations, designs, icons,
            downloads, and other materials (referred to as "Content") displayed
            on this website. Accessing the website does not grant permission to
            use any Content, name, logo, or trademark in any manner. You may
            only print or download the Content for personal, non-commercial use.
            No rights, title, or interest in any Content are transferred to you
            through downloading, printing, or copying. It is prohibited to
            reproduce, publish, transmit, distribute, display, modify, create
            derivative works from, sell, or participate in the sale or
            exploitation of any Content or related software, either in whole or
            in part. All software utilized on this website is the property
            ofAuction Gold or its licensors/suppliers and is protected by Indian
            and international copyright laws.
          </p>
          <div className="common-hd-terms">External Material & links:</div>
          <p className="common-text">
            The Auction Gold website may include links to other websites. Please
            note that these external websites are beyond our control. We cannot
            be held accountable for the content or reliability of these
            websites, nor do we provide any warranties or guarantees regarding
            them. We offer these links as a convenience and believe they may be
            of interest to you, but we do not actively monitor or endorse the
            content on these websites.
          </p>
          <div className="common-hd-terms">Limitation of Liability:</div>
          <p className="common-text">
            To the fullest extent permitted by applicable law, Auction Gold, its
            officers, directors, employees, agents, and affiliates shall not be
            liable for any direct, indirect, incidental, special, or
            consequential damages, including, but not limited to, damages for
            loss of profits, goodwill, data, or other intangible losses
            resulting from the use or inability to use the website, services, or
            content, even if advised of the possibility of such damages. In no
            event shall Auction Gold' liability exceed the amount paid by you,
            if any, for accessing or using the website.
          </p>
          <div className="common-hd-terms">Reviews, Feedback, Submissions:</div>
          <p className="common-text">
            Exclusive property of Auction Gold. By disclosing, submitting, or
            offering such comments, you assign all worldwide rights, titles, and
            interests in copyrights and other intellectual properties contained
            in the comments to Auction Gold. As a result, Auction Gold has the
            sole ownership of these rights, titles, and interests, and is
            unrestricted in their use, whether commercial or otherwise, without
            any obligation to compensate you. Auction Gold has no obligation to:
          </p>
          <ul><strong className="privacy-subtext">a.</strong> Maintain any comments in confidence,</ul>
          <ul><strong className="privacy-subtext">b.</strong> Provide compensation for any comments, or</ul>
          <ul><strong className="privacy-subtext">c.</strong> Respond to any comments.</ul>
          <p className="common-text">
            You agree and confirm that any comments, suggestions, reviews, or
            feedback you submit to us or on the website will not violate these
            Terms and Conditions, our Privacy Policy, or the rights of any third
            party, including copyrights, trademarks, privacy, or other personal
            or proprietary rights. Your submissions will not cause harm to any
            individual or entity. Furthermore, you agree that your comments will
            not contain unlawful, threatening, abusive, or obscene material,
            software viruses, political campaigning, commercial solicitation,
            chain letters, mass emails, or any form of spam. While Auction
            Golddoes not regularly review posted comments, we reserve the right
            (but not the obligation) to monitor, edit, or remove any comments
            submitted to the website. By submitting comments, you grantAuction
            Gold the right to use your submitted name in connection with those
            comments. You agree not to use a false email address, impersonate
            any person or entity, or mislead as to the origin of your comments.
            You are solely responsible for the content of any comments,
            suggestions, reviews, or feedback you provide, and you agree to
            indemnify Auction Goldand its affiliates for any claims arising from
            your submissions.Auction Gold not liable for any comments,
            suggestions, reviews, or feedback submitted by you or any third
            party.
          </p>
          <div className="common-hd-terms">Indemnification:</div>
          <p className="common-text">
            By using this website, you agree to indemnify, defend, and hold
            harmless Auction Gold, its officers, directors, employees, agents,
            and affiliates from and against any and all claims, damages, losses,
            liabilities, costs, and expenses (including legal fees) arising from
            or in connection with your use of the website or any violation of
            these Terms and Conditions.
          </p>
          <div className="common-hd-terms">Governing Law and Jurisdiction</div>
          <p className="common-text">
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of India. Any disputes arising under or in
            connection with these Terms and Conditions shall be subject to the
            exclusive jurisdiction of the courts
          </p>
          <div className="common-hd-terms">Force Majeure:</div>
          <p className="common-text" >
            Auction Gold shall not be held responsible for any delay or failure
            to perform our obligations under these terms and conditions, and we
            shall not be liable for any resulting loss or damages if such delay
            or failure is caused by circumstances beyond our control. These
            circumstances may include, but are not limited to, acts of God, war,
            civil disturbances, riots, strikes, lockouts, government or
            parliamentary restrictions, prohibitions, or enactments, pandemics,
            import or export regulations, exchange control regulations,
            accidents, or non-availability/delay in transportation.
          </p>
          <div className="common-hd-terms">Termination:</div>
          <p className="common-text">
            Auction Gold reserves the right to terminate or suspend your access
            to and use of the website at any time, without notice. As a result,
            your access to the website may be denied. This termination will not
            impose any liability on Auction Gold. The rights ofAuction Gold will
            remain in effect even after termination. The termination of your
            access to the website will not release you from your obligation to
            pay for any products already ordered from the website, nor will it
            affect any liabilities that may have arisen under these Terms and
            Conditions.
          </p>
          <p className="common-text">
            Contact Us: If you have any questions or concerns about these Terms
            and Conditions, please contact our customer support team at
            auction.luckystargold.com or +971 54 581 6161
          </p>
        </Container>
        <Footer/>
      </div>
  
    </>
  );
}
export default Terms;
