import { Container } from "react-bootstrap";
import AuctionBoxes from "../../../components/Auction";
import "./styles.scss";
import { useEffect, useState } from "react";
import { API } from "../../../config/api";
import { Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import { POST } from "../../../utils/apiCalls";

export default function Aution() {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [data, setData] = useState<any>();

  useEffect(() => {
    loadData(1, 6, "", "");
  }, []);

  const loadData = async (page: any, take: any, query: any, date: any) => {
    try {
      const url = API.LIST_AUCTION;

      let obj = {
        page: page,
        take: take,
        query: query,
        enddate: date,
      };
      let res: any = await POST(url, obj);
      if (res?.status) {
        setData(res.data);
        setIsloading(false);
      }
    } catch (error) {
      console.log("=error=", error);
    }
  };
  return (
    <>
      <div className="Auction_Container">
        <Container>
          {isLoading ? (
            <Skeleton active />
          ) : (
            <>
              <div className="Auction_Containerview">
                <div className="Auction_Containerviewbox2">Auctions</div>
                <div
                  className="Auction_Containerviewbox1"
                  onClick={() => navigate(`listingAuctions`)}
                >
                  See more 
                </div>
              </div>
              <AuctionBoxes data={data} />
            </>
          )}
        </Container>
      </div>
    </>
  );
}
