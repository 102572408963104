import { useEffect, useState } from "react";
import {  Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import arrow from "../../../assets/images/arrows.png";
import { API } from "../../../config/api";
import { POST } from "../../../utils/apiCalls";
import "./style.scss";
import { FaLongArrowAltRight } from "react-icons/fa";
function FirstSection() {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [data, setData] = useState<any>();

  useEffect(() => {
    loadData(1, 2, "", "");
  }, []);

  const loadData = async (page: any, take: any, query: any, date: any) => {
    try {
      const url = API.LIST_AUCTION;
      let obj = {
        page: page,
        take: take,
        query: query,
        enddate: date,
      };

      let response: any = await POST(url, obj);
      if (response) {
        setData(response.data);
        setIsloading(false);
      } else {
        console.log("error");
        setIsloading(false);
      }
    } catch (error) {
      console.log("=error=", error);
      setIsloading(false);
    }
  };
  const rating = [
    {
      count: "10K+",
      title: "Satisfied Customers",
    },
    {
      count: "98%",
      title: "Positive Feedback",
    },
    {
      count: "$1M+",
      title: "Auctions Sold",
    },
  ];
  return (
    <Row>
      <Col lg={6} md={6} sm={12}>
        <div className="FirstSectionBox1">
          <div className="FirstSectionBox2">
            <h1>MAIN AUCTIONS</h1>
          </div>
          <div className="FirstSectionBox3">    
            <p>Win confidently on our dynamic auction platform today!</p>
          </div>
          <br />
          <div className="landing_BOx7">
            {data?.[1]?.images?.[0] ? (
              <>
                <div className="FirstSectionBox4Second">
                  <div className="FirstSectionBox5Second"></div>
                  <div
                    className="FirstSectionBox6second"
                    style={{
                      backgroundImage: `url(${data?.[1]?.images?.[0]})`,
          border: "solid 1px rgb(212, 209, 209)"
                    }}
                  ></div>
                </div>
                <div className="secondImageTxtBox">
                  <div className="secondImageTxt1">{data?.[1]?.name}</div>
                  <div className="secondImageTxt2">
                    {data?.[1]?.description}
                  </div>
                  <div
                    className="secondImageTxt3"
                    onClick={() => navigate(`/details/${data?.[1]?.id}`)}
                  >
                    Bid Now <FaLongArrowAltRight style={{cursor:"pointer"}} size={25} color="#000" />
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className="landing_BOx8Mob">
            {rating.map((item: any) => (
              <div className="landing_BOx8">
                <div className="landing_BOx9">{item.count}</div>
                <div className="landing_BOx10">{item.title}</div>
              </div>
            ))}
          </div>
        </div>
      </Col>
      <Col lg={6} md={6} sm={12}>
        {data?.[0]?.images?.[0] ? (
          <div className="FirstSectionBox4">
            <div className="FirstSectionBox5"></div>
            <div
              className="FirstSectionBox6"
              style={{ border: "solid 1px rgb(212, 209, 209)", backgroundImage: `url(${data?.[0]?.images?.[0]})` }}
            ></div>
          </div>
        ) : null}
      </Col>
      <Row>
        <div className="FirstSectionBox7">
          <div className="FirstSectionBox7Sub">
          Over 10,000 auctions from<br/>
          around the world
          </div>
          <div className="FirstSectionBox7Sub">
          Consistent quality and pricing 
            <br /> in everything
          </div>
          <img className="FirstSectionBox7Subimg"
            src={arrow}
            onClick={() => navigate(`/details/${data?.[0]?.id}`)}
          />
        </div>
      </Row>
      <div className="BottomLine">
        <div className="BottomLineSub"></div>
      </div>
    </Row>
  );
}

export default FirstSection;
