import React, { useEffect, useState } from "react";
import { Card } from "antd";
import UpdateProfile from "../../components/updateProfile";
import UpdatePassword from "../../components/updatePassword";
import { API } from "../../config/api";
import { GET, PUT } from "../../utils/apiCalls";
import { useSelector } from "react-redux";
import {  Form,  } from "antd";



const EditProfile = (props: any) => {
  const [showProfile, setShowProfile] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState<any>({});
  const User = useSelector((state: any) => state.User.user);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (showProfile) getStaff();
  }, [showProfile]);

  const getStaff = async () => {
    try {
      const url = API.USER_GET_ID + User?.id;
      const response: any = await GET(url, null);
      console.log("work response======", response);


      setData(response?.data);
    } catch (err) {
      console.log(err);
    }
  };
  const [form] = Form.useForm();

  useEffect(() => {
    // getStaff();
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      name: props?.data?.name,
      address: props?.data?.address,
      national: props?.data?.nationality,
      code: props?.data?.code,
      phoneNo: props?.data?.phoneNo,
      email: props?.data?.email,
      password: props?.data?.password
    })
  }, [props])
  const onFinish = async (val: any) => {
    try {
      let UpdatUlrl = "user/" + props?.id;
      let obj = {
        name: val?.name,
        address: val?.address,
        nationality: val?.national,
        code: val?.code,
        phoneNo: parseInt(val?.phoneNo),
        password: val?.password,
        email: val.email
      };

      const response = await PUT(UpdatUlrl, obj);


    } catch (err: any) {
      console.log("error====", err);
  
     
    }
  };

  return (
    <div>
      {/* <Row >
        <Col md={6}>
          <div className="Profile-Box1">
            <div style={{ fontSize: "22px", display: "flex", justifyContent: "center" }}>
              <img style={{ width: "30%", }} src={logo} />
            </div>
            <br />
            <Form form={form} onFinish={onFinish} >
              <Row>
                <Col md={6}>
                  <label className="newsFormLabels"> Full Name</label>
                  <Form.Item name={"name"}>
                    <Input className="Profile-input" size="large" />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <label className="newsFormLabels"> Email Address</label>
                  <Form.Item name={"email"}>
                    <Input className="Profile-input" size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label className="newsFormLabels">Nationality</label>
                  <Form.Item name={"national"}>
                    <Select className="Profile-input" style={{ background: "transparent" }}>
                      <Select.Option value={"India"}>India</Select.Option>
                      <Select.Option value={"Soudi"}>Soudi</Select.Option>
                      <Select.Option value={"qatar"}>qatar</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={6}>


                  <label className="newsFormLabels">Phone Number</label>
                  <Form.Item name={"phoneNo"}>
                    <Input
                      className="Profile-input"
                      placeholder="Phone...."
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label className="newsFormLabels">Address</label>

                  <Form.Item name={"address"}>
                    <Input className="Profile-input"
                      placeholder="Address...."
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col md={6}>

                  <label className="newsFormLabels">Password</label>
                  <Form.Item name={"password"}>
                    <Input.Password className="Profile-input" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="Profile-BtnBox">
                <Button style={{ width: "100%", height: "40px" }} htmlType="submit" type="primary">
                  submit
                </Button>
              </div>
            </Form>
          </div>
        </Col>
        <Col md={6}></Col>


      </Row> */}
      <br />
      <Card onClick={() => setShowProfile(true)}>Update Profiled</Card>
      <br />
      <Card onClick={() => setShowPassword(true)}>Update password</Card>
      {showProfile ? (
        <UpdateProfile
          id={User.id}
          data={data}
          visible={showProfile}
          close={() => setShowProfile(false)}
        />
      ) : null}
      {showPassword ? (
        <UpdatePassword
          visible={showPassword}
          close={() => setShowPassword(false)}
        />
      ) : null}
    </div>
  );
};

export default EditProfile;
