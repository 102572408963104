import { Button, Modal } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import done from "../../assets/images/donepayment.gif";
import refund from "../../assets/images/free-refund-3132162-2606390.png";
import { API } from "../../config/api";
import { DELETE, POST } from "../../utils/apiCalls";
import "./styles.scss";

export default function RefundModal(props: any) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [refundDone, setRefundDone] = useState(false);
  const [wallet, setWallet] = useState<any>([]);

  const loadWallet = async () => {
    try {
      const wlldata = props?.data.map(async (item: any) => {
        let url = API.GET_BY_USERWALLET;
        let obj = {
          id: Number(item.UserId),
        };
        try {
          const response: any = await POST(url, obj);
          if (response?.status) {
            return { ...item, walletData: response.data };
          } else {
            return {};
          }
        } catch (err) {
          console.log(err);
          return {};
        }
      });

      const data = await Promise.all(wlldata);
      return data;
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  const refundFaildBids = async () => {
    setIsLoading(true);
    try {
      const fetchdata = (await loadWallet()).map(async (item: any) => {
        let url = API.UPDATE_WALLET;
        let obj = {
          type: "Refund(Failed Bid)",
          transactionId: "_refund_faildbid",
          amount: Number(item?.bidPrice),
          date: new Date().toISOString(),
          failed_Bid: item?.auction_details,
        };
        let body = {
          id: Number(item?.walletData?.[0]?.id),
          wallet_Amount:
            Number(item?.walletData?.[0]?.wallet_Amount) +
            Number(item?.bidPrice),
          payment_details: obj,
        };
        try {
          const response: any = await POST(url, body);
          if (response) {
            setIsLoading(false);
            await isertTransaction(response);
            deleteBid(item?.id);
            SendNotification(response);
            setRefundDone(true);
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const isertTransaction = async (val: any) => {
    let body = {
      mode: val?.data?.type || val?.data?.payment_details?.type,
      wallet_ID: Number(val?.data?.id),
      user_ID: Number(val?.data?.user_ID),
      transaction_details: val?.data?.payment_details,
      failed_Bid: val?.data?.payment_details?.failed_Bid,
    };
    let url = API.CREATE_TRANSACTION;
    try {
      const response: any = await POST(url, body);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteBid = async (id: any) => {
    try {
      let url = API.DELETE_BID + id;
      let response: any = await DELETE(url);
      if (response.status) {
        props.refresh();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const SendNotification = async (val: any) => {
    try {
      let url = API.CREATE_NOTIFICATION;
      let body = {
        UserId: Number(val?.data?.user_ID),
        auction_ID: Number(val?.data?.payment_details?.failed_Bid?.id),
        Heading: "Bid Unsuccessful - Better Luck Next Time!",
        Body: `Hi [User's Name],  We regret to inform you that your bid for [item/service] was not successful.
        We understand this might be disappointing, but don't worry - there are always more opportunities to come! You can check out other ongoing auctions [here/link].
        Thank you for participating and we hope to see you again soon.`,
      };
      let response: any = await POST(url, body);
      if (response?.status) {
      }
    } catch (error) {}
  };

  return (
    <Modal
      open={props.visible}
      onCancel={() => {
        props.close();
        setRefundDone(false);
      }}
      width={400}
      footer={false}
      centered
    >
      <div className="RefundModalBox1">
        {refundDone ? (
          <>
            <img src={done} />
            <Button
              type="primary"
              className="CloseButton"
              onClick={() => {
                props.close();
                setRefundDone(false);
              }}
            >
              Close
            </Button>
          </>
        ) : (
          <>
            <img src={refund} />
            <br />
            <h2>Refund</h2>
            <h6>Refund for failed bids</h6>
            <Button
              loading={isLoading}
              type="primary"
              className="RefundButton"
              onClick={() => refundFaildBids()}
            >
              Refund
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
}
