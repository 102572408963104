export const API = {
  // BASE_URL: "http://localhost:8022/",
  BASE_URL: "https://server.bairuhatech.com/auction-server/",

  // total bid count 

  GET_BID_TOTAL:"bid/Bidsum",
  GET_A_AUCTION: "auction/find_one",
  GET_AUCTION: "auction/picker",
  DELETE_AUCTION: "auction/delete",
  CREATE_AUCTION: "auction/create",
  UPDATE_AUCTION: "auction/update",
  LIST_AUCTION: "auction/list",
  LIST_AUCTIONBY_CAT: "auction/listByCat",
  ADD_FAVORITE: "auction/addtofavorite",

  LIST_BANNER: "banner/list",
  DELETE_BANNER: "banner/delete",
  CREATE_BANNER: "banner/create",
  UPDATE_BANNER: "banner/update",

  GET_CATEGORIES: "category/picker",
  LIST_CATEGORY: "category/list",
  DELETE_CATEGORY: "category/delete",
  CREATE_CATEGORY: "category/create",
  UPDATE_CATEGORY: "category/update",
  GET_CATEGORY_BY_ONEAUCTION: "category/pickerByCategory",

  CREATE_BID: "bid/create",
  GET_BIDBY_USER: "bid/bidbyuser",
  LIST_BIDS: "bid/list",
  COUNTBID_BYITEM: "bid/bidcount",

  CREATE_FAVOURITE: "Favourites/create",
  DELETE_FAVOURITE: "Favourites/delete",
  GET_BY_ITEM: "Favourites/getbyAuction",
  GET_FAVOURITES: "Favourites/picker",
  USER_FAVOURITES: "Favourites/favebyuser",
  ///login

  COMPRESS_IMAGE: "img_compress/compress",
  COMPRESS_BULKIMAGE: "img_compress/bulkcompress",

  USER_LOGIN: "user/login",
  GET_USER: "user/picker",
  USER_GET_ID: "user/find_one/",
  GET_USER_USER: "user/pickerForUser",
  ///////edit profile
  UPDATE_PROFILE: "user/updateUser",
  DELETE_BID: "bid/delete/",
  CREATE_USER: "user/create",

  
  CHANGE_PASSWORD: "user/changePassword",
  VERIFY_OTP: "user/VerifyOTP",
  UPDATE_PASSWORD: "user/UpdatePassword",

  //SMS Login
  REQUEST_OTP: "sms/request-otp",
  VERIFY_SMS_OTP: "sms/verify-otp",

  //Wallet
  CREATE_WALLET: "wallet/create",
  GET_BY_USERWALLET: "wallet/listByUser",
  UPDATE_WALLET: "wallet/update",

  //transaction
  CREATE_TRANSACTION: "transaction/create",
  GET_BY_USERTRANSCTION: "transaction/listByUser",

  //ContactUs
  CREATE_ENQUIRY: "contactUs/create",

  //Notification
  CREATE_NOTIFICATION: "notification/create",
  DELETE_NOTIFI_BY_AYCTION: "notification/deleteByaction",
  NOTIFICATION_BY_USER: "notification/notifcationbyuser",
  NOTIFICATION_BY_ID: "notification/notifcationbyID",
  READED_NOTIFICATIONS: "notification/Readlist",
  UNREADE_NOTIFICATIONS: "notification/UnReadlist",
  UPDATE_NOTIFICATION: "notification/update",
};
