
import { LiaUserSolid } from "react-icons/lia";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import profile from "../../assets/images/beauty.jpeg";
import DynamicIcon from "./dynamicIcon";
import Menu from "./menu.json";
import "./styles.scss";
import { Card, Tag } from "antd";
import { Col, Row } from "react-bootstrap";
import { TbArticleFilledFilled } from "react-icons/tb";
import { BsFillCreditCardFill } from "react-icons/bs";
import { API } from "../../config/api";
import { POST } from "../../utils/apiCalls";
import { useEffect, useState } from "react";

const NaveBar = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const User = useSelector((state: any) => state.User.user);
  const [Wallet, setWallet] = useState<any>();

  useEffect(() => {
    if (User?.id) {
      loadWallet();
    }
  }, [User?.id]);

  const loadWallet = async () => {
    let url = API.GET_BY_USERWALLET;
    let obj = {
      id: Number(User.id),
    };

    try {
      const response: any = await POST(url, obj);
    
      
      if (response) {
        setWallet(response.data);
      } else {
 
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="sideBarUser">
      <Card className="profile_Box1">
        <Row>
          <Col>
            <img src={profile} alt="profile" className="profile_Box3" />
            <div className="profile_Box6">{User?.name}</div>
            <div className="profile_Box7">{User?.email}</div>
            <br />
            <div style={{ display: "flex", gap: "5px" }}>
              {/* <Tag className="profileTags">Bids: 2 {Wallet?.wallet_Amount}</Tag> */}
              <Tag className="profileTags">Wallet: ${Wallet?.[0]?.wallet_Amount || 0}</Tag>
            </div>
          </Col>
        </Row>
      </Card>
      <Card className="profile_Box2" onClick={() => navigate(`/usr/myBids`)}>
        <div className="profile_Box2MyBIds">
          <TbArticleFilledFilled size={25} />
          <h6>My Bids</h6>
        </div>
      </Card>
      <Card
        className="profile_Box2CArd2"
        onClick={() => navigate(`/usr/transactions`)}
      >
        <div className="profile_Box2MyBIds">
          <BsFillCreditCardFill size={25} />
          <h6>Transaction</h6>
        </div>
      </Card>
    </div>
  );
};

export default NaveBar;
