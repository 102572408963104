import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../components/header";
import "./styles.scss";
import Footer from "../../components/footer";

function AboutUs() {
  return (
    <>
      <div className="AboutUsScreeen">
        <Header />
        <Container>
          <div className="AboutUsScreeen_Main">
            <div className="headline">
              Auction Gold – Yes, Your Gold Destination!{" "}
            </div>
            <div className="headline">TURNING A CONCEPT INTO A REALITY</div>
            <div className="common-text">
              Since its inception in 2005, auction Gold has come a long way and
              achieved many milestones to become one of the leading powerhouse
              retailers in the region. UAE’s firstever Big-Box concept started
              its journey as a 15,000 sq ft gold store in Dubai. The brand’s
              perseverance and hard work has paid off with 33 stores in United
              Arab Emirates,Bahrain, Egypt & Oman today and more in the offing
            </div>
            <div className="headline">
              WIDEST CHOICE, BEST VALUE, TRUSTED SERVICE
            </div>
            <div className="common-text">
              With over 25,000 gold products and accessories to choose from
              reputed international brands, auction Gold’s distinctive style of
              enhancing customer lifestyle has left an indelible mark on the
              consumers mind,making us a household name to be reckoned with. We
              pride ourselves on our exemplary service and product knowledge at
              the store level,coupled with the widest range of gold available at
              the best value on display at one location.
            </div>
            <div className="headline">KEY DIFFERENTIATORS</div>
            <div className="common-text">
              Our 24 hour ‘Product not available, claim free’ brand promise is
              auction Gold assurance to customers which ensures that all product
              availability concerns are met with satisfactorily.auction Gold
              ensures that the products are authorized to avoid any post
              purchase difficulty faced by customers. The auction Gold ‘best
              price guarantee’ to customers is another highlight which ensures
              that customers get the best value for any product across all
              auction Gold stores. In case of customer finding a product cheaper
              elsewhere, auction Goldensures that the price is matched.It
              strengthens customers’ trust in auction Gold in delivering the
              best price in the market.
            </div>
            <div className="headline">POSITIVE REINFORCEMENT</div>
            <div className="common-text">
              Every month over 1 million customers visit our stores to make
              their purchases and enjoy the exceptional service and ambience
              that auction Gold has become famous for. This is further
              reinforced by our customers’ and vendors’ endorsement towards
              auction Gold as their premier gold destination through awards and
              accolades that we have collected over the years
            </div>
          </div>

          <ul style={{ margin: "0px" }}>
            <li className="common-text">
              Superbrand 2017, 2016, 2015, 2014, 2013, 2012, 2011
            </li>
            <li className="common-text">
              Retail ME 2011: Most admired Retailer of the year: consumer gold
            </li>
            <li className="common-text">
              LG retailer awards 2011: Best support and best in-store display
              across select categories
            </li>
            <li className="common-text">
              Reseller Awards 2011: Best Retailer of the year by Vendors &
              Resellers’ choice of channel awards
            </li>
            <li className="common-text">
              MAF Awards 2011: Anchor Store of the Year
            </li>
            <li className="common-text">
              MAF Awards 2011: Retailer of the year
            </li>
            <li className="common-text">
              Dubai Shopping Festival 2010: Innovation Award
            </li>
            <li className="common-text">
              Middle East Retail Academy Awards 2009: Best Retailer for
              Marketing/Promotion
            </li>
            <li className="common-text">
              Middle East Retail Academy Awards 2009: Store Concept of the Year
            </li>
            <li className="common-text">
              Channel Middle East Award 2009: Retail Company of the year
            </li>
            <li className="common-text">
              VAR Magazine 2009: Vendor’s Choice Preferred Retailer of the Year
            </li>
            <li className="common-text">
              ACER Awards 2009: Power Retailer of the Year
            </li>
          </ul>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default AboutUs;
